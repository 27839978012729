<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="110px" label-position="left">
        <el-form-item label="发货类型">
          <el-radio-group v-model="shippingObj.type">
            <el-radio :label="1">电子面单</el-radio>
            <el-radio :label="2">手动发货</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="shippingObj.type == 1" label="面单模板">
          <el-select v-model="shippingObj.model" placeholder="请选择面单模板">
          </el-select>
        </el-form-item>
        <el-form-item v-if="shippingObj.type == 2" label="发货方式">
          <el-radio-group v-model="shippingObj.delivery">
            <el-radio :label="1">物流发货</el-radio>
            <el-radio :label="2">无需物流</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="shippingObj.delivery == 1 && shippingObj.type == 2" label="物流公司">
          <el-select v-model="shippingObj.logistics" placeholder="请选择物流公司">
          </el-select>
        </el-form-item>
    </el-form>
    <el-table :data="orderList" max-height="350">
      <el-table-column label="订单号" prop="order_sn"></el-table-column>
      <el-table-column label="收货地址" prop="address"></el-table-column>
      <el-table-column v-if="shippingObj.delivery == 1 && shippingObj.type == 2" label="物流单号">
        <template slot-scope="scope">
          <el-input v-model="scope.row.logistics_sn" placeholder="请输入物流单号"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="orderList.splice(scope.$index,1)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "shipping",
  props:{
    id: {
      type: Number,
      default: []
    },
  },
  data(){
    return {
      shippingObj: {
        type: 1,
        model: '',
        delivery: 1,
        logistics: '',
      },
      orderList: [
        {
          order_sn: '123456789',
          address: '深圳市南山区科技园',
          logistics_sn: '123456789',
        },
        {
          order_sn: '123456789',
          address: '深圳市南山区科技园',
          logistics_sn: '123456789',
        },
        {
          order_sn: '123456789',
          address: '深圳市南山区科技园',
          logistics_sn: '123456789',
        },
        {
          order_sn: '123456789',
          address: '深圳市南山区科技园',
          logistics_sn: '123456789',
        },
        {
          order_sn: '123456789',
          address: '深圳市南山区科技园',
          logistics_sn: '123456789',
        },
        {
          order_sn: '123456789',
          address: '深圳市南山区科技园',
          logistics_sn: '123456789',
        },
      ],
    }
  },
  methods:{
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.close()
    }
  }
}
</script>

<style scoped>

</style>