<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="110px" label-position="left">
      <el-form-item label="面单模板">
        <el-select v-model="model"></el-select>
      </el-form-item>
      <el-form-item label="是否发货">
        <el-switch v-model="isDelivery"/>
      </el-form-item>
    </el-form>
    <span>tips：电子面单将调用快递100/快递鸟的接口直接打印出来，请参考手册设置。</span>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "printTicket",
  props:{
    id: {
      type: Number,
      default: []
    },
  },
  data(){
    return{
      model: '',
      isDelivery: false,
    }
  },
  methods:{
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.close()
    }
  }
}
</script>

<style scoped>

</style>